import { atom, createStore } from "jotai";
import { atomWithStorage } from "jotai/utils";
export const productsStockAtom = atom([]);
export const productsCartAtom = atom([]);
export const formAtom = atom({
  name: "",
  category: "",
  stockQtyStatus: "",
  cost: "",
  price: "",
  image: null,
  existingImage: null,
});
export const totalAmountAtom = atom(0);
export const customersAtom = atom([]);
export const orderTotalAmountAtom = atom(0);
export const ordersAtom = atomWithStorage("Orders", []);
export const ordersFormData = atom({
  warehouseId: "",
  branchId: "",
  productId: "",
  quantity: "",
  status: "Pending",
});

// User atom to manage user data, e.g., user list, current user, roles, etc.
export const userAtom = atom({
  users: [], // List of users
  currentUser: null, // Currently logged-in user
  roles: [], // Roles available in the system
});

export const userFormAtom = atom({
  username: "",
  password: "",
  role: "",
  name: "",
  email: "",
  title: "",
  place: "",
  about: "",
  language: "",
  timezone: "",
  syncData: true,
});

export const userRoleAtom = atomWithStorage("user_role", null);

export const api = process.env.REACT_APP_API_URL || "http://82.180.154.251:5000";

const JotaiStore = createStore(
  api,
  productsStockAtom,
  productsCartAtom,
  formAtom,
  totalAmountAtom,
  orderTotalAmountAtom,
  customersAtom,
  ordersAtom,
  ordersFormData,
  userAtom,
  userFormAtom,
  userRoleAtom
);

export default JotaiStore;
