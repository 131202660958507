// import axios from "axios";

// const initializeApp = () => {
//   // Setting base URL for all API request via axios
//   axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

//   if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
//     // dev code
//   } else {
//     // Prod build code

//     // Removing console.log from prod
//     console.log = () => {};

//     // init analytics here
//   }
// };

// export default initializeApp;
import axios from "axios";

// A utility for logging, can be expanded for different logging levels
const logger = {
  log: (...args) => {
    if (process.env.NODE_ENV !== "production") {
      console.log(...args);
    }
  },
  error: (...args) => {
    console.error(...args);
  },
};

const initializeAnalytics = () => {
  // Initialize analytics here, e.g., Google Analytics or similar
  logger.log("Analytics initialized");
};

const initializeApp = () => {
  // Setting base URL for all API requests via axios
  const baseURL = process.env.REACT_APP_BASE_URL;

  if (!baseURL) {
    logger.error("Base URL is not defined in environment variables.");
    return; // Exit if the base URL is not defined
  }

  axios.defaults.baseURL = baseURL;

  if (process.env.NODE_ENV === "development") {
    // Development specific code
    logger.log("Running in development mode");
  } else {
    // Production specific code
    logger.log("Running in production mode");
    
    // Initialize analytics in production
    initializeAnalytics();
  }
};

export default initializeApp;
