// import React, { createContext, useContext, useEffect, useState } from "react";
// import axios from "axios";
// import Cookies from "js-cookie";

// const AuthContext = createContext();

// // Function to set axios defaults
// const setAxiosDefaults = (token) => {
//   if (token) {
//     axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
//   } else {
//     delete axios.defaults.headers.common["Authorization"];
//   }
// };

// export const AuthProvider = ({ children }) => {
//   const [user, setUser] = useState(null);
//   const [loading, setLoading] = useState(true);

//   const checkAuth = () => {
//     const token = Cookies.get("token");
//     const refreshToken = Cookies.get("refreshToken");
//     const PUBLIC_ROUTES = ["login", "forgot-password", "register"];
//     const isPublicPage = PUBLIC_ROUTES.some((r) =>
//       window.location.pathname.includes(r)
//     );

//     if (!token && !isPublicPage) {
//       window.location.href = "/login";
//       return;
//     }

//     let userRole = null;

//     if (token) {
//       try {
//         const payload = JSON.parse(atob(token.split(".")[1]));
//         userRole = payload.role;
//         setUser({ id: payload.id, role: userRole });
//         setAxiosDefaults(token);
//       } catch (err) {
//         console.error("Invalid token format", err);
//         logout();
//       }
//     } else {
//       setAxiosDefaults();
//     }

//     setLoading(false);
//   };

//   // Handle token refresh
//   const refreshAuthToken = async () => {
//     const refreshToken = Cookies.get("refreshToken");
//     if (refreshToken) {
//       try {
//         const response = await axios.post("/auth/refresh-token", {
//           token: refreshToken,
//         });
//         const { token, refreshToken: newRefreshToken } = response.data;

//         // Save tokens in cookies
//         Cookies.set("token", token, { expires: 1 }); // Set a 1-day expiration
//         Cookies.set("refreshToken", newRefreshToken, { expires: 30 }); // Set a 30-day expiration
//         setAxiosDefaults(token);
//       } catch (error) {
//         console.error("Error refreshing token:", error);
//         logout();
//       }
//     }
//   };

//   const logout = () => {
//     console.log("Logging out...");

//     // Remove tokens from cookies with an explicit path
//     Cookies.remove("token", { path: "" }); // Adjust the path as necessary
//     Cookies.remove("refreshToken", { path: "" }); // Adjust the path as necessary

//     // Check if tokens were successfully removed
//     const tokenAfterLogout = Cookies.get("token");
//     const refreshTokenAfterLogout = Cookies.get("refreshToken");
//     // console.log("Token after logout:", tokenAfterLogout);
//     // console.log("Refresh Token after logout:", refreshTokenAfterLogout);

//     // Clear user state
//     setUser(null);

//     // Clear axios defaults
//     setAxiosDefaults();

//     // Navigate to login
//     setTimeout(() => {
//       window.location.href = "/login"; // Ensures cleanup is complete before redirecting
//     }, 100);
//   };

//   console.log("Token after logout:", Cookies.get("token"));
//   console.log("Refresh Token after logout:", Cookies.get("refreshToken"));

//   useEffect(() => {
//     checkAuth();
//     const intervalId = setInterval(() => {
//       refreshAuthToken();
//     }, 1000 * 60 * 25); // Refresh token every 25 minutes

//     return () => clearInterval(intervalId); // Cleanup interval on unmount
//   }, []);

//   return (
//     <AuthContext.Provider value={{ user, loading, refreshAuthToken, logout }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

// // Custom hook for using AuthContext
// export const useAuth = () => {
//   return useContext(AuthContext);
// };
// src/context/AuthContext.js
import React, { createContext, useContext, useState } from "react";
import { loginUser, logoutUser } from "../services/api"; // Assuming you have these functions

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const login = async (credentials) => {
    const loggedInUser = await loginUser(credentials);
    setUser(loggedInUser);
  };

  const logout = () => {
    logoutUser();
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
