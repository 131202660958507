import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Async thunk for login (you can add others like register, logout if needed)
export const login = createAsyncThunk(
  "auth/login",
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await axios.post("/login", credentials);
      const { token } = response.data;

      // Store the token in localStorage
      localStorage.setItem("token", token);

      return response.data; // return token and other user info
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Slice for auth state
const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: localStorage.getItem("token") || null,
    userRole: localStorage.getItem("userRole") || null,
    userId: null,
    loading: false,
    error: null,
  },
  reducers: {
    logout(state) {
      localStorage.removeItem("token");
      localStorage.removeItem("userRole");
      state.token = null;
      state.userRole = null;
      state.userId = null;
      axios.defaults.headers.common["Authorization"] = null;
    },
    checkAuth(state) {
      const token = localStorage.getItem("token");
      if (token && token.split(".").length === 3) {
        try {
          const payload = JSON.parse(atob(token.split(".")[1]));
          state.userRole = payload.role;
          state.userId = payload.userId;
          state.token = token;
          localStorage.setItem("userRole", payload.role);
          axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        } catch (err) {
          console.error("Error decoding token", err);
          localStorage.removeItem("token");
          window.location.href = "/login";
        }
      } else {
        localStorage.removeItem("token");
        window.location.href = "/login";
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        const { token, userId, role } = action.payload;
        state.token = token;
        state.userId = userId;
        state.userRole = role;
        state.loading = false;

        // Set token in Axios header
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to login";
      });
  },
});

export const { logout, checkAuth } = authSlice.actions;
export default authSlice.reducer;
