// src/features/websocket/websocketSlice.js
import { createSlice } from "@reduxjs/toolkit";

const websocketSlice = createSlice({
  name: "websocket",
  initialState: {
    messages: [],
    isConnected: false,
  },
  reducers: {
    addMessage: (state, action) => {
      state.messages.push(action.payload);
    },
    setConnectionStatus: (state, action) => {
      state.isConnected = action.payload;
    },
  },
});

export const { addMessage, setConnectionStatus } = websocketSlice.actions;

export default websocketSlice.reducer;
