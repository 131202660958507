import React, { lazy, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { themeChange } from "theme-change";
import checkAuth from "./app/auth";
import initializeApp from "./app/init";
import ErrorBoundary from "./utils/ErrorBoundary";
import UnauthorizedPage from "./pages/UnauthorizedPage";

// Importing pages
const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const Register = lazy(() => import("./pages/Register"));

// Initializing different libraries
initializeApp();

// Check for login and initialize axios
const token = checkAuth();
console.log("Token from App.js: ",token)

function App() {
  useEffect(() => {
    themeChange(false);
  }, []);

  return (
    <>
      <ErrorBoundary>
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/register" element={<Register />} />
            <Route path="/unauthorized" element={<UnauthorizedPage />} />

            {/* Place new routes over this */}
            <Route path="/app/*" element={<Layout />} />

            <Route
              path="*"
              element={
                <Navigate to={token ? "/app/welcome" : "/login"} replace />
              }
            />
          </Routes>
        </Router>
      </ErrorBoundary>
    </>
  );
}

export default App;

// //////////////////////////////////////////
// //////////////////////////////////////////
// //////////////////////////////////////////

// import React, { lazy, useEffect } from "react";
// import "./App.css";
// import {
//   BrowserRouter as Router,
//   Route,
//   Routes,
//   Navigate,
// } from "react-router-dom";
// import { themeChange } from "theme-change";
// import { useDispatch, useSelector } from "react-redux";
// import { checkAuth } from "./app/authSlice";
// import initializeApp from "./app/init";
// import ErrorBoundary from "./utils/ErrorBoundary ";
// import UnauthorizedPage from "./pages/UnauthorizedPage";
// // import GlobalLoader from "./components/GlobalLoader";

// // Importing pages
// const Layout = lazy(() => import("./containers/Layout"));
// const Login = lazy(() => import("./pages/Login"));
// const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
// const Register = lazy(() => import("./pages/Register"));

// // Initializing different libraries
// initializeApp();

// function App() {
//   const dispatch = useDispatch();
//   const { token } = useSelector((state) => state.auth);

//   useEffect(() => {
//     themeChange(false);

//     // Check authentication on app load
//     dispatch(checkAuth());
//   }, [dispatch]);

//   return (
//     <>
//       <ErrorBoundary>
//         <Router>
//           {/* Global loading indicator */}
//           {/* {globalLoading && <GlobalLoader />} */}

//           <Routes>
//             <Route path="/login" element={<Login />} />
//             <Route path="/forgot-password" element={<ForgotPassword />} />
//             <Route path="/register" element={<Register />} />
//             <Route path="/unauthorized" element={<UnauthorizedPage />} />

//             {/* Place new routes over this */}
//             <Route
//               path="/app/*"
//               element={token ? <Layout /> : <Navigate to="/login" replace />}
//             />

//             <Route
//               path="*"
//               element={
//                 <Navigate to={token ? "/app/welcome" : "/login"} replace />
//               }
//             />
//           </Routes>
//         </Router>
//       </ErrorBoundary>
//     </>
//   );
// }

// export default App;
