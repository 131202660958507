// import { configureStore } from "@reduxjs/toolkit";
// import headerSlice from "../features/common/headerSlice";
// import modalSlice from "../features/common/modalSlice";
// import rightDrawerSlice from "../features/common/rightDrawerSlice";

// const combinedReducer = {
//   header: headerSlice,
//   rightDrawer: rightDrawerSlice,
//   modal: modalSlice,
// };

// export default configureStore({
//   reducer: combinedReducer,
// });
// src/app/store.js
import { configureStore } from "@reduxjs/toolkit";
import headerSlice from "../features/common/headerSlice";
import modalSlice from "../features/common/modalSlice";
import rightDrawerSlice from "../features/common/rightDrawerSlice";
import websocketSlice from "../features/common/websocketSlice"; 
import authReducer from "./authSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    header: headerSlice,
    rightDrawer: rightDrawerSlice,
    modal: modalSlice,
    websocket: websocketSlice, // Add websocket slice here if applicable
  },
});

export default store; // Default export
