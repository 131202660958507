// // // export default checkAuth;
// // import axios from "axios";

// // // Function to check authentication and manage axios settings
// // const checkAuth = () => {
// //   // Get the token from localStorage
// //   const TOKEN = localStorage.getItem("token");

// //   // Define public routes that don't require authentication
// //   const PUBLIC_ROUTES = ["login", "forgot-password", "register"];

// //   // Check if the current route is public
// //   const isPublicPage = PUBLIC_ROUTES.some((r) =>
// //     window.location.pathname.includes(r)
// //   );

// //   // Redirect to login if no token and not on a public route
// //   if (!TOKEN && !isPublicPage) {
// //     window.location.href = "/login";
// //     return null; // No need to proceed further
// //   }

// //   if (TOKEN) {
// //     // Set the Authorization header for axios requests if a token is present
// //     axios.defaults.headers.common["Authorization"] = `Bearer ${TOKEN}`;
// //   } else {
// //     // If no token and on a public page, remove Authorization header just in case
// //     delete axios.defaults.headers.common["Authorization"];
// //   }

// //   // Add request interceptor to show loading indicator
// //   axios.interceptors.request.use(
// //     function (config) {
// //       document.body.classList.add("loading-indicator");
// //       return config;
// //     },
// //     function (error) {
// //       document.body.classList.remove("loading-indicator");
// //       return Promise.reject(error);
// //     }
// //   );

// //   // Add response interceptor to hide loading indicator and handle 401 errors
// //   axios.interceptors.response.use(
// //     function (response) {
// //       document.body.classList.remove("loading-indicator");
// //       return response;
// //     },
// //     function (error) {
// //       document.body.classList.remove("loading-indicator");

// //       // Check for unauthorized errors (e.g., token expired or invalid)
// //       if (error.response && error.response.status === 401) {
// //         // Optionally, you can clear the token and redirect to login
// //         localStorage.removeItem("token");
// //         window.location.href = "/login";
// //       }

// //       return Promise.reject(error);
// //     }
// //   );

// //   return TOKEN; // Return the token for further use if needed
// // };

// // export default checkAuth;

// // ///////////////////////////////////////////////////////////////
// // ///////////////////////////////////////////////////////////////
// // ///////////////////////////////////////////////////////////////
// // ///////////////////////////////////////////////////////////////

// // import axios from "axios";

// // // Function to check authentication and manage axios settings
// // const checkAuth = () => {
// //   // Get the token from localStorage
// //   const TOKEN = localStorage.getItem("token");

// //   // Define public routes that don't require authentication
// //   const PUBLIC_ROUTES = ["login", "forgot-password", "register"];

// //   // Check if the current route is public
// //   const isPublicPage = PUBLIC_ROUTES.some((r) =>
// //     window.location.pathname.includes(r)
// //   );

// //   // Redirect to login if no token and not on a public route
// //   if (!TOKEN && !isPublicPage) {
// //     window.location.href = "/login";
// //     return null; // No need to proceed further
// //   }

// //   let userRole = null; // Initialize userRole variable

// //   if (TOKEN) {
// //     // Set the Authorization header for axios requests if a token is present
// //     axios.defaults.headers.common["Authorization"] = `Bearer ${TOKEN}`;

// //     // Decode the token to get user roles (assuming JWT structure)
// //     const payload = JSON.parse(atob(TOKEN.split(".")[1]));
// //     userRole = payload.role; // Set userRole from the token

// //     // Store user role in localStorage for easy access
// //     localStorage.setItem("userRole", userRole);
// //   } else {
// //     // If no token and on a public page, remove Authorization header just in case
// //     delete axios.defaults.headers.common["Authorization"];
// //   }

// //   // Add request interceptor to show loading indicator
// //   axios.interceptors.request.use(
// //     function (config) {
// //       document.body.classList.add("loading-indicator");
// //       return config;
// //     },
// //     function (error) {
// //       document.body.classList.remove("loading-indicator");
// //       return Promise.reject(error);
// //     }
// //   );

// //   // Add response interceptor to hide loading indicator and handle 401 errors
// //   axios.interceptors.response.use(
// //     function (response) {
// //       document.body.classList.remove("loading-indicator");
// //       return response;
// //     },
// //     function (error) {
// //       document.body.classList.remove("loading-indicator");

// //       // Check for unauthorized errors (e.g., token expired or invalid)
// //       if (error.response && error.response.status === 401) {
// //         // Optionally, you can clear the token and redirect to login
// //         localStorage.removeItem("token");
// //         localStorage.removeItem("userRole"); // Clear user role as well
// //         window.location.href = "/login";
// //       }

// //       return Promise.reject(error);
// //     }
// //   );

// //   return { TOKEN, userRole }; // Return both the token and user role for further use if needed
// // };

// // export default checkAuth;
// import axios from "axios";

// // Function to check authentication and manage axios settings
// // const checkAuth = () => {
// //   const TOKEN = localStorage.getItem("token");
// //   const PUBLIC_ROUTES = ["login", "forgot-password", "register"];
// //   const isPublicPage = PUBLIC_ROUTES.some((r) =>
// //     window.location.pathname.includes(r)
// //   );

// //   if (!TOKEN && !isPublicPage) {
// //     window.location.href = "/login";
// //     return null;
// //   }

// //   let userRole = null;

// //   if (TOKEN) {
// //     try {
// //       const payload = JSON.parse(atob(TOKEN.split(".")[1]));
// //       userRole = payload.role;
// //       localStorage.setItem("userRole", userRole);
// //       axios.defaults.headers.common["Authorization"] = `Bearer ${TOKEN}`;
// //     } catch (err) {
// //       console.error("Invalid token format", err);
// //       localStorage.removeItem("token");
// //       window.location.href = "/login";
// //     }
// //   } else {
// //     delete axios.defaults.headers.common["Authorization"];
// //   }
// const checkAuth = () => {
//   const TOKEN = localStorage.getItem("token");
//   const PUBLIC_ROUTES = ["login", "forgot-password", "register"];
//   const isPublicPage = PUBLIC_ROUTES.some((r) =>
//     window.location.pathname.includes(r)
//   );

//   if (!TOKEN && !isPublicPage) {
//     window.location.href = "/login";
//     return null;
//   }

//   let userRole = null;

//   if (TOKEN) {
//     try {
//       const payload = JSON.parse(atob(TOKEN.split(".")[1]));
//       userRole = payload.role;
//       localStorage.setItem("userRole", userRole);
//       axios.defaults.headers.common["Authorization"] = `Bearer ${TOKEN}`;
//     } catch (err) {
//       console.error("Invalid token format", err);
//       localStorage.removeItem("token");
//       window.location.href = "/login";
//     }
//   } else {
//     delete axios.defaults.headers.common["Authorization"];
//   }
//   axios.interceptors.request.use(
//     (config) => {
//       document.body.classList.add("loading-indicator");
//       return config;
//     },
//     (error) => {
//       document.body.classList.remove("loading-indicator");
//       return Promise.reject(error);
//     }
//   );

//   axios.interceptors.response.use(
//     (response) => {
//       document.body.classList.remove("loading-indicator");
//       return response;
//     },
//     (error) => {
//       document.body.classList.remove("loading-indicator");

//       if (error.response && error.response.status === 401) {
//         localStorage.removeItem("token");
//         localStorage.removeItem("userRole");
//         window.location.href = "/login";
//       }

//       return Promise.reject(error);
//     }
//   );

//   return { TOKEN, userRole };
// };

// export default checkAuth;
import axios from "axios";

const checkAuth = () => {
  const TOKEN = localStorage.getItem("token");
  const PUBLIC_ROUTES = ["login", "forgot-password", "register"];
  const isPublicPage = PUBLIC_ROUTES.some((r) =>
    window.location.pathname.includes(r)
  );

  // Redirect to login if there's no token and the page is not public
  if (!TOKEN && !isPublicPage) {
    window.location.href = "/login";
    return null;
  }

  let userRole = null;
  let userId = null;

  if (TOKEN) {
    // Check if token format is valid
    if (TOKEN.split(".").length === 3) {
      try {
        const payload = JSON.parse(atob(TOKEN.split(".")[1]));
        userRole = payload.role; // Ensure this matches your token payload
        userId = payload.userId; // Retrieve user ID if it exists
        localStorage.setItem("userRole", userRole);

        // Set the token in Axios default headers
        axios.defaults.headers.common["Authorization"] = `Bearer ${TOKEN}`;
      } catch (err) {
        console.error("Error decoding token", err);
        localStorage.removeItem("token");
        window.location.href = "/login";
      }
    } else {
      console.error("Invalid token format");
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }

  // Axios request interceptor for loading indicator
  axios.interceptors.request.use(
    (config) => {
      document.body.classList.add("loading-indicator");
      return config;
    },
    (error) => {
      document.body.classList.remove("loading-indicator");
      return Promise.reject(error);
    }
  );

  // Axios response interceptor for loading indicator
  axios.interceptors.response.use(
    (response) => {
      document.body.classList.remove("loading-indicator");
      return response;
    },
    (error) => {
      document.body.classList.remove("loading-indicator");

      // Handle unauthorized errors
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("userRole");
        window.location.href = "/login";
      }

      return Promise.reject(error);
    }
  );

  return { TOKEN, userRole, userId }; // Return token, userRole, and userId
};

export default checkAuth;
