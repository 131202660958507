import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import store from "./app/store";
import { Provider } from "react-redux";
import SuspenseContent from "./containers/SuspenseContent";
import { Provider as JotaiProvider } from "jotai";
import JotaiStore from "./atoms/store";
import { AuthProvider } from "./context/AuthContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Suspense fallback={<SuspenseContent />}>
        <JotaiProvider store={JotaiStore}>
          <AuthProvider>
            <App />
          </AuthProvider>
        </JotaiProvider>
      </Suspense>
    </Provider>
  </React.StrictMode>
);
