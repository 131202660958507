import axios from "axios";

export const API_URL = "http://82.180.154.251:5000";

const api = axios.create({
  baseURL: API_URL,
});

// User Management
export const getUsers = async (token) => {
  return api.get(`/users`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getUserById = async (userId, token) => {
  if (!userId) {
    console.error("User ID is undefined");
    throw new Error("User ID is required");
  }

  try {
    const response = await api.get(`/users/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data; // Return only the data part
  } catch (error) {
    console.error("Error fetching user by ID:", error);
    throw error; // Re-throw the error to handle it in the caller function
  }
};

export const getTargetById = async (userId, token) => {
  try {
    const response = await axios.get(`/users/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`, // Include token in the headers for authentication
      },
    });
    return response.data; // Assuming your API returns the user details in response.data
  } catch (error) {
    console.error("Error fetching user by ID:", error);
    throw error; // Re-throw the error to be handled in the calling function
  }
};

// Admin Only user creation
export const createUser = async (userData, token) => {
  return api.post("/users", userData, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const registerUser = async (userData) => {
  // No token required for registration
  return api.post("/users/register", userData);
};

// Function to log in a user
export const loginUser = async (credentials) => {
  try {
    const response = await axios.post(`${API_URL}/users/login`, credentials, {
      withCredentials: true,
    });
    return response.data; // Return user data on successful login
  } catch (error) {
    console.error("Login failed:", error);
    throw error.response
      ? error.response.data
      : { message: "An error occurred during login" };
  }
};

// Function to log out a user
export const logoutUser = async () => {
  try {
    const response = await axios.post(
      `${API_URL}/users/logout`,
      {},
      {
        withCredentials: true,
      }
    );
    return response.data; // Optionally return some confirmation message or status
  } catch (error) {
    console.error("Logout failed:", error);
    throw error.response
      ? error.response.data
      : { message: "An error occurred during logout" };
  }
};

export const updateUser = async (id, userData, token) => {
  return api.put(`/users/${id}`, userData, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const deleteUser = async (id, token) => {
  return api.delete(`/users/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// Role Management
export const getRoles = async (token) => {
  return api.get("/roles", {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const createRole = async (roleData, token) => {
  return api.post("/roles", roleData, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const updateRole = async (id, roleData, token) => {
  return api.put(`/roles/${id}`, roleData, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const deleteRole = async (id, token) => {
  return api.delete(`/roles/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// Orders Management
export const getOrders = async (token) => {
  return api.get("/orders", {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getDeliveredOrders = async (token) => {
  return api.get("/orders/delivered", {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// Get all orders related to the authenticated user
export const getUserOrders = async (token) => {
  return api.get("/orders/mine", {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getOrderById = async (id, token) => {
  return api.get(`/orders/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const createOrder = async (orderData, token) => {
  return api.post("/orders", orderData, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const updateOrder = async (id, orderData, token) => {
  return api.put(`/orders/${id}`, orderData, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const deleteOrder = async (id, token) => {
  return api.delete(`/orders/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// Warehouse Management
export const getWarehouses = async (token) => {
  return api.get("/warehouses", {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getWarehouseById = async (id, token) => {
  return api.get(`/warehouses/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getWarehouseProducts = async (warehouseId, token) => {
  return api.get(`/warehouse/${warehouseId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const createWarehouse = async (warehouseData, token) => {
  return api.post("/warehouses", warehouseData, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const updateWarehouse = async (id, warehouseData, token) => {
  return api.put(`/warehouses/${id}`, warehouseData, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const deleteWarehouse = async (id, token) => {
  return api.delete(`/warehouses/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// Branch Management
export const getBranches = async (token) => {
  return api.get("/branches", {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getBranchById = async (id, token) => {
  return api.get(`/branches/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const createBranch = async (branchData, token) => {
  return api.post("/branches", branchData, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const updateBranch = async (id, branchData, token) => {
  return api.put(`/branches/${id}`, branchData, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const deleteBranch = async (id, token) => {
  return api.delete(`/branches/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getUserBranches = async (token) => {
  return api.get("/branches/mine", {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// Product Management
export const getProducts = async (token) => {
  return api.get("/products", {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getProductById = async (id, token) => {
  return api.get(`/products/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const createProduct = async (productData, token) => {
  return api.post("/products", productData, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const updateProduct = async (id, productData, token) => {
  return api.put(`/products/${id}`, productData, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const deleteProduct = async (id, token) => {
  return api.delete(`/products/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const addProductToBranches = async (branchProductData, token) => {
  return api.post("/branch/product", branchProductData, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getBranchesProducts = async (branchId, token) => {
  return api.get(`/branch/${branchId}/products`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// Branch Product Management (stock)
export const updateBranchProduct = async (
  branchId,
  productId,
  stockData,
  token
) => {
  return api.post(`/branch/${branchId}/product/${productId}/stock`, stockData, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const deleteBranchProduct = async (branchId, productId, token) => {
  return api.delete(`/branch/${branchId}/product/${productId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// Warehouse Product Management

// Add product to warehouse
export const addProductToWarehouse = async (warehouseProductData, token) => {
  return api.post("/warehouse/product", warehouseProductData, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// Get products from a specific warehouse
export const getWarehousesProducts = async (warehouseId, token) => {
  return api.get(`/warehouse/${warehouseId}/products`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// Update product in warehouse
export const updateWarehouseProduct = async (
  warehouseId,
  productId,
  productData,
  token
) => {
  return api.put(
    `/warehouse/${warehouseId}/product/${productId}`,
    productData,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

// Delete product from warehouse
export const deleteWarehouseProduct = async (warehouseId, productId, token) => {
  return api.delete(`/warehouse/${warehouseId}/product/${productId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// Audit Log Management

// Get audit logs
export const getAuditLogs = async (token) => {
  return api.get("/logs", {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// Get audit log by ID
export const getAuditLogById = async (id, token) => {
  return api.get(`/log/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// Create a new audit log entry
export const createAuditLog = async (auditData, token) => {
  return api.post("/log", auditData, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// Update an audit log entry
export const updateAuditLog = async (id, auditData, token) => {
  return api.put(`/log/${id}`, auditData, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// Delete an audit log entry
export const deleteAuditLog = async (id, token) => {
  return api.delete(`/log/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
