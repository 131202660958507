// import { createSlice } from "@reduxjs/toolkit";

// export const modalSlice = createSlice({
//   name: "modal",
//   initialState: {
//     title: "", // current title state management
//     isOpen: false, // modal state management for opening/closing
//     bodyType: "", // modal content management
//     size: "", // modal size management
//     extraObject: {},
//   },
//   reducers: {
//     openModal: (state, action) => {
//       const { title, bodyType, extraObject, size } = action.payload;
//       state.isOpen = true;
//       state.bodyType = bodyType;
//       state.title = title;
//       state.size = size || "md";
//       state.extraObject = extraObject;
//     },
//     closeModal: (state) => {
//       state.isOpen = false;
//       state.bodyType = "";
//       state.title = "";
//       state.extraObject = {};
//     },
//   },
// });

// export const { openModal, closeModal } = modalSlice.actions;

// export default modalSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

export const modalSlice = createSlice({
  name: "modal",
  initialState: {
    title: "", // current title state management
    isOpen: false, // modal state management for opening/closing
    bodyType: "", // modal content management
    size: "", // modal size management
    extraObject: {}, // Can hold book data for editing
  },
  reducers: {
    openModal: (state, action) => {
      const { title, bodyType, extraObject, size } = action.payload;
      state.isOpen = true;
      state.bodyType = bodyType;
      state.title = title;
      state.size = size || "md";
      state.extraObject = extraObject || {}; // Ensure it handles book data for editing
    },
    closeModal: (state) => {
      state.isOpen = false;
      state.bodyType = "";
      state.title = "";
      state.extraObject = {};
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;

export default modalSlice.reducer;
